import React from 'react'
import classnames from 'classnames'

import './style.scss'

interface ISelect {
  list: { value: string; label: string }[]
  className?: string
  onChange: (value: string) => void
}

const Select: React.FC<ISelect> = ({ list, className, onChange }) => {
  return (
    <select
      className={classnames('select', className)}
      onChange={value => onChange(value.target.value)}
    >
      {list.map(v => (
        <option value={v.value} key={v.value}>
          {v.label}
        </option>
      ))}
    </select>
  )
}

export default Select
