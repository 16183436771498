import React from 'react'
import classnames from 'classnames'

import { Card } from '../../components'
import { useSettings } from '../../hooks'

import './style.scss'

const Settings: React.FC = () => {
  const { getSettings, setSound } = useSettings()

  return (
    <div className="settings-panel">
      <div onClick={setSound}>
        <Card.Environment>
          <div
            className={classnames(
              'sound-bars',
              !getSettings()?.sound && 'sound-disabled'
            )}
          >
            <div
              className="sound-bar"
              style={{
                animationPlayState: getSettings()?.sound ? 'running' : 'paused',
              }}
            />
            <div
              className="sound-bar"
              style={{
                animationPlayState: getSettings()?.sound ? 'running' : 'paused',
              }}
            />
            <div
              className="sound-bar"
              style={{
                animationPlayState: getSettings()?.sound ? 'running' : 'paused',
              }}
            />
            <div
              className="sound-bar"
              style={{
                animationPlayState: getSettings()?.sound ? 'running' : 'paused',
              }}
            />
            <div
              className="sound-bar"
              style={{
                animationPlayState: getSettings()?.sound ? 'running' : 'paused',
              }}
            />
            <div className="sound-line"></div>
          </div>
        </Card.Environment>
      </div>
    </div>
  )
}

export default Settings
