const text = {
  en: {
    technologies: 'Technologies',
    experience: 'Work experience',
    education: 'Education',
    // technologies
    often: 'day-to-day comfort',
    rarely: 'experience with',
    // Experience
    experienceList: [
      {
        title: 'Incora Software Development Company',
        period: 'From Jul 2021',
        description: `Full-stack Software Engineer`,
      },
      {
        title: 'Self-employed',
        period: 'Jun 2020 – Jul 2021',
        description: `I worked with private entrepreneurs in the field of sales. I work in the position of Full-stack Software Engineer. Created projects:`,
      },
    ],
    // education
    educationList: [
      {
        title: 'Lviv National Agrarian University',
        description: `Specialty 126 "Information Systems and Technologies" 2017 - 2023`,
      },
      {
        title: 'Tvirzhany Educational Complex',
        description: `«Complex educational institution I - ІІІ degrees - preschool educational institution» Mostyska city council of Lviv region (finished in 2017)`,
      },
    ],
  },
  ua: {
    technologies: 'Технології',
    experience: 'Досвід роботи',
    education: 'Освіта',
    // technologies
    often: 'Комфортно використовую',
    rarely: 'Був досвід з',
    // Experience
    experienceList: [
      {
        title: 'Incora Software Development Company',
        period: 'З Липня 2021',
        description: `Full-stack Software Engineer`,
        image:
          'https://incorainc.com/wp-content/uploads/2020/03/logo-white.png',
      },
      {
        title: 'Самозайнятий',
        period: 'Червень 2020 – Липень 2021',
        description: `Працював з приватними підприємцями у сфері збуту.  Створені проєкти:`,
      },
    ],
    // education
    educationList: [
      {
        title: 'Львівський національний аграрний університет',
        description: `Спеціальність 126 "Інформаційні системи та технології" 2017 - 2023 роки`,
      },
      {
        title: 'Освіта Твіржанський навчально-виховний комплекс',
        description: `«Комплексний навчальний заклад I - ІІІ ступенів - дошкільний навчальний заклад» Мостиська міська рада Львівської області (закінчено у 2017 році)`,
      },
    ],
  },
  oftenList: [
    'JavaScript / Typescript',
    'Node.js (Express, Koa, Nest)',
    'REST API / GraphQL',
    'MySql / Postgresql / MongoDB',
    'React (SPA / Next.js / Gatsby.js)',
    'HTML5 / CSS3',
    'Version Control (GIT)',
    'Windows / Linux / macOS',
  ],
  rarelyList: [
    'AWS (Amazon Web Services)',
    'Serverless',
    'React Native / PWA',
    'Three.js / D3.js',
    'SQLite / MSSQL / Redis',
    'Jenkins',
    'Rust',
    'C++',
  ],
  certificates: [
    {
      badge: '/badges/aws/practitioner.png',
      certificate: '/certificates/aws/practitioner.pdf',
    },
    {
      badge: '/badges/aws/solutions-architect-associate.png',
      certificate: '/certificates/aws/solutions-architect-associate.pdf',
    },
    {
      badge: '/badges/aws/developer.png',
      certificate: '/certificates/aws/developer.pdf',
    },
  ],
}

export default text
