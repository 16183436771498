import React from 'react'
import { useQueryParam, NumberParam } from 'use-query-params'

interface ContextProps {
  readonly page: number
  readonly setPage: (page: number) => void
  readonly count: number
  readonly setCount: (page: number) => void
}

export const RouterContext = React.createContext<ContextProps>({
  page: 0,
  count: 0,
  setPage: () => {},
  setCount: () => {},
})

export const RouterProvider: React.FC<{ page: number }> = ({
  page: initialPage,
  children,
}) => {
  const [count, setCount] = React.useState(0)
  const [page = initialPage, setPage] = useQueryParam('screen', NumberParam)

  React.useEffect(() => {
    setPage(
      Number(new URL(String(document.location)).searchParams.get('screen'))
    )
  }, [])

  return (
    <RouterContext.Provider
      value={{
        page: page ?? initialPage,
        setPage,
        count,
        setCount,
      }}
    >
      {children}
    </RouterContext.Provider>
  )
}
