import classNames from 'classnames'
import React from 'react'

import { Typography } from '../../fragments'
import { Card } from '../../components'

import './style.scss'

interface Props {
  value: boolean
  onClick: () => void
  label: string
  Icon?: any
  className?: string
}

const Toggle: React.FC<Props> = ({
  onClick,
  value,
  label,
  Icon,
  className,
}) => {
  return (
    <Card.Environment>
      <div
        className={classNames(
          'toggle-button',
          value ? 'toggle-active' : null,
          className
        )}
        onClick={onClick}
      >
        {Icon ? <Icon className="toggle-icon" /> : null}
        <Typography component="p">{label}</Typography>
      </div>
    </Card.Environment>
  )
}

export default Toggle
