import React from 'react'
import classNames from 'classnames'

import { DefaultFlex } from '.'

import './style.scss'

interface Props extends DefaultFlex {
  children: any
  className?: string
  justify?: any
  align?: any
  wrap: any
  onClick?: () => void
}

const Row: React.FC<Props> = ({
  children,
  className,
  justify,
  align,
  wrap,
  onClick,
  margin,
  padding,
}) => {
  return (
    <div
      className={classNames(
        'row',
        className,
        margin && 'margin',
        padding && 'padding'
      )}
      style={{ alignItems: align, justifyContent: justify, flexWrap: wrap }}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

export default Row
