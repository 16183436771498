import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import './style.scss'

interface Props {
  from?: 'left' | 'right' | 'bottom' | 'top' | 'opacity'
  out?: 'opacity' | 'deg'
  delay?: string
  className?: string
  children?: any
}

const Fade: React.FC<Props> = ({ children, from, className, delay, out }) => {
  const [animation, setAnimation] = useState(false)

  return (
    <div
      className={classNames(
        from && `animation-fade-${from}`,
        out && `animation-fade-out-${out}`,
        'fade-animation',
        className
      )}
      style={{ animationDelay: delay }}
    >
      {children}
    </div>
  )
}

export default Fade
