import React from 'react'

import { Typography, FadeAnimation } from '../../fragments'
import { Card } from '../../components'

import Full from './Full'

import './style.scss'

interface Props {
  name: string
  position: number
  onClick: () => void
}

interface ChildProps {
  name: string
  description: string
  url: string
  status: string
  showLink: boolean
  onBack: () => void
}

const Project: React.FC<Props> & {
  Full: React.FC<ChildProps>
} = ({ name, onClick, position }) => {
  return (
    <FadeAnimation from="opacity" delay={`${position * 2}00ms`}>
      <Card.Environment>
        <div className={'project'} onClick={onClick}>
          <img src={`/project/${name}-desk.jpg`} alt={name} />

          <Card className="project-name">
            <Card.Content>
              <Typography size="medium" animate>
                {name}
              </Typography>
            </Card.Content>
          </Card>
        </div>
      </Card.Environment>
    </FadeAnimation>
  )
}

Project.Full = Full

export default Project
