const text = {
  en: {
    list: ['', 'About', 'Resume', 'Projects', 'Scene', 'Contacts'],
    info: `Site info © penhersky.net`,
  },
  ua: {
    list: ['', 'Хто я', 'Резюме', 'Проєкти', 'Сцена', 'Контакти'],
    info: `Інформація про сайт © penhersky.net`,
  },
}

export default text
