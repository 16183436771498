import React from 'react'
import classnames from 'classnames'

import { Typography } from '..'

import './style.scss'

interface ICopyArea {
  children: React.ReactNode
  content: string
  copyText?: string
}

const Area: React.FC<ICopyArea> = ({
  children,
  content,
  copyText = 'Copy',
}) => {
  const [copied, setCopied] = React.useState(false)

  const onCopyHandler = () => {
    setCopied(true)
    navigator.clipboard.writeText(content)
  }

  return (
    <div className={classnames('copy-area', copied ? 'copy-area__copied' : '')}>
      <div className="copy-area__content">{children}</div>

      <div className="copy-area__action" onClick={onCopyHandler}>
        <Typography>{copied ? 'Copied' : copyText}</Typography>
      </div>
    </div>
  )
}

export default Area
