const text = {
  en: {
    online: 'online',
    closed: 'closed',
    paused: 'suspended',
    toSite: 'go to site',
    list: [
      {
        name: 'space-movies',
        description:
          'The first non-commercial test project was created by me to study client development using the React.js library.',
        status: 'online',
        url: 'https://space-movies.netlify.app',
        company: '',
        showLink: true,
        stackFront: ['Typescript', 'React.js', 'Material UI', 'Scss'],
      },
      {
        name: 'kingsale',
        description:
          'The first commercial project of an online store for retail sale of electric fireplaces and barbecues. Now it works and is actively developing.',
        status: 'online',
        url: 'https://kingsale.com.ua',
        company: 'Self-employed',
        showLink: true,
        stackFront: ['Typescript', 'React.js', 'Material UI', 'Scss'],
        stackBack: [
          'Typescript',
          'Node.js',
          'Express',
          'MongoDB',
          'Redux-thunk',
        ],
      },
      {
        name: 'dliakuhni',
        description:
          'Online store that specializes in the sale of kitchen goods and appliances.',
        status: 'closed',
        url: 'https://dliakuhni.com',
        company: 'Self-employed',
        showLink: true,
        stackFront: ['Typescript', 'React.js', 'Material UI', 'Scss'],
        stackBack: [
          'Typescript',
          'Node.js',
          'Express',
          'MySql',
          'Redux-thunk',
          'Telegraf',
        ],
      },
      {
        name: 'votsons',
        description:
          'Online store with a wide range of travel bags, cases and suitcases.',
        status: 'closed',
        url: 'https://votsons.com',
        company: 'Self-employed',
        showLink: true,
        stackFront: ['Typescript', 'React.js', 'Material UI', 'Scss'],
        stackBack: [
          'Typescript',
          'Node.js',
          'Express',
          'MySql',
          'Redux-thunk',
          'Telegraf',
        ],
      },
    ],
  },
  ua: {
    online: 'Онлайн',
    closed: 'Закритий',
    paused: 'Призупинений',
    toSite: 'Перейти на ресурс',
    list: [
      {
        name: 'space-movies',
        description:
          'Перший не комерційний тестовий проект створений мною для вивчення клієнтської розробки з використанням бібліотеки React.js.',
        status: 'online',
        url: 'https://space-movies.netlify.app',
        showLink: true,
        stackFront: ['Typescript', 'React.js', 'Material UI', 'Scss'],
      },
      {
        name: 'kingsale',
        description:
          'Перший комерційний проект інтернет магазину з роздрібної торгівлі електрокамінами та мангалами. Зараз працює і активно розвивається.',
        url: 'https://kingsale.com.ua',
        status: 'online',
        company: 'Самозайнятий',
        showLink: true,
        stackFront: ['Typescript', 'React.js', 'Material UI', 'Scss'],
        stackBack: [
          'Typescript',
          'Node.js',
          'Express',
          'MongoDB',
          'Redux-thunk',
        ],
      },
      {
        name: 'dliakuhni',
        description:
          'Інтернет магазин який спеціалізується на продажі кухонних товарів та побутової техніки.',
        status: 'closed',
        url: 'https://dliakuhni.com',
        company: 'Самозайнятий',
        showLink: true,
        stackFront: ['Typescript', 'React.js', 'Material UI', 'Scss'],
        stackBack: [
          'Typescript',
          'Node.js',
          'Express',
          'MySql',
          'Redux-thunk',
          'Telegraf',
        ],
      },
      {
        name: 'votsons',
        description:
          'Інтернет магазин з широким асортиментом дорожніх сумок, кейсів та валіз.',
        status: 'closed',
        url: 'https://votsons.com',
        company: 'closed',
        showLink: true,
        stackFront: ['Typescript', 'React.js', 'Material UI', 'Scss'],
        stackBack: [
          'Typescript',
          'Node.js',
          'Express',
          'MySql',
          'Redux-thunk',
          'Telegraf',
        ],
      },
    ],
  },
}

export default text
