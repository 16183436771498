import { localList } from '../../types/locale'

const getInitialLocale = () => {
  const localSetting = localStorage?.getItem('lng')

  if (localSetting) return localSetting

  const [browserSetting] = navigator.language.split('-')
  if (localList.includes(browserSetting)) {
    return browserSetting
  }

  return localList[0]
}

export default getInitialLocale
