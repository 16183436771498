import React from 'react'
import classNames from 'classnames'

import { Card } from '../../components'

import './style.scss'

interface Props {
  children?: any
  onClick?: () => void
  type?: 'submit' | 'button'
  size?: 'small' | 'medium'
  className?: string
  disabled?: boolean
}

const Button: React.FC<Props> = ({
  children,
  type = 'button',
  onClick,
  size = 'small',
  className,
  disabled,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        'input',
        'button',
        `button-size-${size}`,
        className,
        disabled && 'button-disabled'
      )}
    >
      <Card.Environment>{children}</Card.Environment>
    </button>
  )
}

export default Button
