import React, { useContext } from 'react'
import { useSound } from 'use-sound'

import Router, { Props } from './components/Router'
import {
  LocaleContext,
  SettingsContext,
  RouterContext,
  locales,
} from './lib/Context'

import fonSound from './static/sound/fon.mp3'

export const useTranslation = (
  defaultObj?: any
): {
  tr: (key: string, obj?: any) => string | any
  getLng: () => string
  setLng: (lng: string) => void
} => {
  const { locale, setLocale } = useContext(LocaleContext)

  if (!locales.includes(locale as string)) setLocale(locales[0])

  const tr = (key: string, obj?: any): string | Array<any> | null =>
    (obj ? obj : defaultObj)?.[
      locales.includes(locale as string) ? (locale as string) : locales[0]
    ]?.[key]

  const getLng = (): string => locale as string

  const setLng = (lng: string) => {
    setLocale(lng)
    localStorage?.setItem('lng', lng)
  }

  return { tr, getLng, setLng }
}

export const useSettings = (): {
  getSettings: () => {
    sound: boolean
  }
  setSound: () => void
} => {
  const [play, { pause }] = useSound(fonSound, {
    volume: 0.1,
  })
  const { setSound, sound } = useContext(SettingsContext)

  const getSettings = (): any => ({
    sound,
  })

  const setSounds = () => {
    setSound(!sound)
    if (!sound) play()
    else pause()
  }

  return { getSettings, setSound: setSounds }
}

export const useRouter = (): {
  getPage: () => number
  setPage: (page: number) => void
  Router: React.FC<Props>
  count: number
} => {
  const { setPage, page, count } = useContext(RouterContext)

  React.useEffect(() => {
    if (!count) return
    if (count < page + 1) setPage(0)
  }, [count, page])

  const getPage = (): any => page

  const setCurrentPage = (page: number) => setPage?.(page)

  return { getPage, setPage: setCurrentPage, Router, count }
}
