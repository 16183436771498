import React from 'react'

interface ContextProps {
  readonly sound: boolean
  readonly setSound: (locale: boolean) => void
}

export const SettingsContext = React.createContext<ContextProps>({
  sound: false,
  setSound: () => null,
})

export const SettingsProvider: React.FC<{ sound: boolean }> = ({
  sound: initialSound,
  children,
}) => {
  const [sound, setSound] = React.useState(initialSound)

  return (
    <SettingsContext.Provider value={{ sound, setSound }}>
      {children}
    </SettingsContext.Provider>
  )
}
