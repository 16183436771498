import React from 'react'
import classNames from 'classnames'

import './style.scss'

export interface Props {
  children: any
  style?: React.CSSProperties
  className?: string
}

const Sounds: React.FC<Props> = ({ children, style, className }) => {
  return (
    <div className={classNames('card-content', className)} style={style}>
      {children}
    </div>
  )
}

export default Sounds
