import classNames from 'classnames'
import React from 'react'

import { Card } from '../../components'

import './style.scss'

interface Props {
  value: string
  onChange: (value: string) => void
  err?: boolean
  type?: 'button' | 'textarea' | 'datetime-local' | 'password'
  placeholder?: string
  name?: string
  defaultValue?: string
}

const Input: React.FC<Props> = ({
  type,
  onChange,
  value,
  placeholder,
  name,
  err,
  defaultValue,
}) => {
  const onChangeHandler = (e: any) => onChange(e.target.value)

  return (
    <Card.Environment>
      <div className={classNames('form-field', err && 'error')}>
        {type && type === 'textarea' ? (
          <textarea
            value={value}
            placeholder={placeholder}
            onChange={onChangeHandler}
            className="input textarea"
            defaultValue={defaultValue}
            name={name}
          />
        ) : (
          <input
            value={value}
            onChange={onChangeHandler}
            className="input"
            type={type}
            placeholder={placeholder}
            defaultValue={defaultValue}
            name={name}
          />
        )}
      </div>
    </Card.Environment>
  )
}

export default Input
