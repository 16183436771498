import React from 'react'

import CursorIcon from '../../static/icons/cursor.svg'

import './style.scss'

const Cursor: React.FC = () => {
  const ref = React.useRef<any>(null)

  React.useEffect(() => {
    document.addEventListener('mousemove', e => {
      ref.current?.setAttribute(
        'style',
        `top: ${e.pageY - 3}px; left: ${e.pageX - 3}px;`
      )
    })
  }, [])

  return (
    <div ref={ref} id="cursor">
      <CursorIcon />
    </div>
  )
}

export default Cursor
