import React from 'react'
import classNames from 'classnames'

import Column from './Column'
import Row from './Row'

import './style.scss'

export interface DefaultFlex {
  margin?: boolean
  padding?: boolean
}

interface Props extends DefaultFlex {
  children: any
  className?: string
  justify?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
  align?: 'center' | 'flex-start' | 'flex-end'
  wrap?: 'wrap' | 'nowrap'
  onClick?: () => void
}

const Flex: React.FC<Props> & {
  Row: React.FC<Props>
  Column: React.FC<Props>
} = ({ children, className, justify, align, onClick, margin, padding }) => {
  return (
    <div
      className={classNames(
        'flex',
        className,
        margin && 'margin',
        padding && 'padding'
      )}
      style={{ justifyContent: justify, alignItems: align }}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

Flex.Column = Column
Flex.Row = Row

export default Flex
