import React from 'react'
import { useSound } from 'use-sound'

import { useSettings } from '../../hooks'

import press from '../../static/sound/press.mp3'

export interface Props {
  onlyHover?: boolean
  children: any
}

const Sounds: React.FC<Props> = ({ children, onlyHover = false }) => {
  const [pressPlay] = useSound(press, {
    volume: 0.1,
  })
  const { getSettings } = useSettings()

  const onClickHandler = () => {
    if (!getSettings().sound || onlyHover) return
    pressPlay()
  }

  return <div onClick={onClickHandler}>{children}</div>
}

export default Sounds
