import React from 'react'

import { RouterContext } from '../../lib/Context'
export interface Props {
  children: any[]
  initialPage?: number
}

const Router: React.FC<Props> = ({ children, initialPage = 0 }) => {
  const { page = initialPage, setCount } = React.useContext(RouterContext)

  React.useEffect(() => {
    setCount(children.length)
  }, [children])

  if ((page as number) > children.length - 1) return children[initialPage]

  return (
    <>
      {typeof children[page as number] === 'object'
        ? children[page as number]
        : null}
    </>
  )
}

export default Router
