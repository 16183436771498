import React from 'react'
import classNames from 'classnames'

import Content, { Props as TContent } from './Content'
import Sound, { Props as TSound } from './Sound'

import './style.scss'

interface Props {
  children: any
  onClick?: () => void
  style?: React.CSSProperties
  className?: string
}

const Card: React.FC<Props> & {
  Content: React.FC<TContent>
  Environment: React.FC<TSound>
} = ({ children, onClick, className, style }) => {
  return (
    <div
      className={classNames('card', className)}
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

Card.Content = Content
Card.Environment = Sound

export default Card
