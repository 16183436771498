import React from 'react'
import { useQueryParam, StringParam } from 'use-query-params'

interface ContextProps {
  readonly locale: string | null
  readonly setLocale: (locale: string) => void
}

export const LocaleContext = React.createContext<ContextProps>({
  locale: 'en',
  setLocale: () => null,
})

export const locales = ['en', 'ua']

export const LocaleProvider: React.FC<{ lng: string }> = ({
  lng,
  children,
}) => {
  const [locale = lng, setLocale] = useQueryParam('lng', StringParam)

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      {children}
    </LocaleContext.Provider>
  )
}
