import classNames from 'classnames'
import React from 'react'

import { Typography, FadeAnimation, Button } from '../../fragments'
import { Flex } from '../../components'
import { useTranslation } from '../../hooks'
import { projects, resume } from '../../text'

import MenuIcon from '../../static/icons/arrow-left.svg'

import './style.scss'

interface Props {
  name: string
  description: string
  url: string
  status: string
  showLink: boolean
  onBack: () => void
  stackFront: string[]
  stackBack?: string[]
}

const Project: React.FC<Props> = ({
  name,
  description,
  url,
  status,
  onBack,
  showLink,
  stackFront,
  stackBack,
}) => {
  const { tr } = useTranslation(projects)

  // const goToSiteHandler = () => {
  //   window.open(url)
  // }

  return (
    <Flex className="full-project" align="center">
      <Flex.Column justify="center">
        <Flex.Row className="project-top">
          <FadeAnimation from="opacity" className="close-project-icon">
            <MenuIcon className="icon-menu" onClick={onBack} />
          </FadeAnimation>
          <Typography className={classNames('status', status)} animate>
            {tr(status)}
          </Typography>
        </Flex.Row>
        <Flex.Row className="project-body" align="flex-start">
          <FadeAnimation from="left" className="images" delay="0.3s">
            <img
              src={`/project/${name}-desk.jpg`}
              className="desk"
              alt={name}
            />
            <img
              src={`/project/${name}-mob.jpg`}
              className="mob fade-animation animation-fade-bottom"
              style={{ animationDelay: '0.5s' }}
              alt={name}
            />
          </FadeAnimation>
          <FadeAnimation from="left" className="description">
            <Typography size="medium" className="title" animate>
              {name}
            </Typography>
            <Typography>{description}</Typography>
            <br />
            <Typography size="medium">{tr('technologies', resume)}</Typography>
            {stackBack && (
              <>
                <Typography size="medium" className="technologies-title">
                  Back-end
                </Typography>
                {stackBack.map(value => (
                  <Typography key={value}>{value}</Typography>
                ))}
              </>
            )}
            <Typography size="medium" className="technologies-title">
              Front-end
            </Typography>
            {stackFront.map(value => (
              <Typography key={value}>{value}</Typography>
            ))}
          </FadeAnimation>
        </Flex.Row>
      </Flex.Column>
    </Flex>
  )
}

export default Project
