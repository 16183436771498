import React from 'react'
import classnames from 'classnames'

import './style.scss'

interface Props {
  size?: 'small' | 'medium' | 'large'
  component?: 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  className?: string
  animate?: boolean
  children: any
}

const Typography: React.FC<Props> = React.memo(
  ({ children, component = 'p', size = 'small', className, animate }) => {
    if (size === 'large' || component === 'h1' || animate)
      return React.createElement(
        component,
        {
          className: classnames(`typography`, `typography-${size}`, className),
        },
        String(children)
          .split('')
          .map((letter: string, i: number) => (
            <span key={letter + i} className="animate-typography">
              {letter}
            </span>
          ))
      )

    return React.createElement(
      component,
      {
        className: classnames(`typography`, `typography-${size}`, className),
      },
      children
    )
  }
)

export default Typography
