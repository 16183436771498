import React from 'react'

import Flex from '../Flex'
import Button from './Button'

import './style.scss'

interface Props {
  list: { value: string; label: string; icon?: string }[]
  value: string
  onClick: (value: string) => void
}

const ToggleList: React.FC<Props> & {
  ToggleButton: React.FC<{
    value: boolean
    onClick: () => void
    label: string
    Icon?: any
    className?: string
  }>
} = ({ list, onClick, value }) => {
  return (
    <Flex>
      <Flex.Row justify="flex-start">
        {list.map((item, i) => (
          <Button
            key={item.value}
            label={item.label}
            Icon={item?.icon}
            value={item.value === value}
            onClick={() => onClick(item.value)}
            className={i !== list.length - 1 ? 'toggle-line' : ''}
          />
        ))}
      </Flex.Row>
    </Flex>
  )
}

ToggleList.ToggleButton = Button

export default ToggleList
